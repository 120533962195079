/**
  Insertamos las fuentes
*/
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;400;500;600;700;800;900&display=swap');

:root {
  --border-radius: 14px;
  --primary-color: #6FC79F;
  --secondary-color: #284729;
  --font-white: #fff;
  --font-main: #707070;
  --form-color: #70707083;
}

body {
  margin: 0;
  color: var(--font-main);
  font-family: 'Raleway', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header.page {
  margin: 2rem 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.maxwidthscreen {
  min-height: 100vh;
}

header .logo img {
  width: 100%;
}
header .logo {
  width: 20%;
  margin-left: 15px;
  margin: 0 auto;
}

header .flag-head {
  margin-right: -30px;
  width: 78%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0.5rem 1.2rem;
  color: var(--font-white);
  background-color: var(--primary-color);
  border-radius: var(--border-radius);
  /* margin-right: -15px; */
}



header .flag-head h2 {
  margin: 0;
}

.form-turn {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  min-height: 60vmin;
  box-sizing: border-box;
  justify-content: center;
}

.form-turn .turn-step {
  padding: 2rem 1rem;
  border-radius: var(--border-radius);
  background-color: #707070;
  min-width: 50%;
}

.form-turn .turn-step .form-group {
  margin: 0;
}

.custom-biosalud {
  background-color: var(--primary-color);
  border: none;
  border-radius: var(--border-radius);
  color: var(--font-white);
  padding: .5rem 2rem;
  outline: none !important;
  transition: .3s background;
  box-shadow: 0 0 10px #aaa;
}

.custom-biosalud:hover {
  background-color: #707070;
  color: #fff;
}

.b-radius-custom {
  border-radius: var(--border-radius) !important;
}

.rigth-fixed-buttons {
  position: fixed;
  right: 15px;
  bottom: 40px;
}

.rigth-fixed-buttons button {
  border: 1px solid #707070;
  background-color: #707070;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  overflow: hidden;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.rigth-fixed-buttons button i {
  color: var(--font-white);
  font-size: 25px;
}

.calendar-turns {
  background-color: #fff;
  overflow: hidden;
  width: 78%;
  border-radius: var(--border-radius);
}



.calendar-turns tbody tr td{
  padding: 0;
}

.calendar-turns tbody tr td article {
  display: flex;
  flex-flow: column nowrap;
  padding: .5rem;
  min-height: 50px;
  max-height: 50px;
  height: 50px;
  cursor: pointer;
  border: 1px solid transparent;
} 

.calendar-turns tbody tr td article:hover {
  border: 1px solid var(--primary-color);
}

.calendar-turns tbody tr td article.feriado:hover,
.calendar-turns tbody tr td article.selected:hover {
  border: 1px solid transparent;
}
.calendar-turns tbody tr td article.feriado {
  background-color: #eee;
}
.calendar-turns tbody tr td article.selected {
  background-color: var(--primary-color);
  color: var(--font-white);
}


.calendar-turns tbody tr td article h5 {
  text-align: center;
  font-weight: bold;
  margin: 0;
}

.calendar-turns tbody tr td article span {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-around;
}

.calendar-turns tbody tr td article span p {
  margin: 0;
  margin-left: 10px;
  font-size: 10px;
}

.calendar-turns tbody tr td article span p strong {
  font-weight: bold;
}

.selected-day-turn {
  background-color: #fff;
  width: 20%;
  padding: 10px;
  border-radius: var(--border-radius);
  height: 50%;
  overflow: hidden;
}
.calendarContainer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  overflow: auto;
}

article.turn-step.calendar {
  display: flex;
  flex-flow: column nowrap;
  width: 80%;
}

.selected-day-turn div {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.selected-day-turn div h6 {
  margin: 0;
}

.selected-day-turn div select {
  margin-left: 5px;
  border: none;
}

.buttons-control {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-top: 20px;
}

.products select{
  width: 100%;
  border-radius: var(--border-radius);
  padding: 0.375rem 0.75rem;
}

.products .products-list {
  margin-top: 10px;
  display: flex;
  flex-flow: row wrap;
}

.max-50 {
  max-width: 50% !important;
  margin-left: auto;
  margin-right: auto;
}

.products .products-list article {
  margin-right: 5px;
  margin-bottom: 5px;
  width: 200px;
  padding: 5px 10px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  background: #fff;
  border-radius: var(--border-radius);
}

.products .products-list article h6 {
  margin: 0;
}

.products .products-list article button {
  margin: 0;
  border: none;
  background: none;
  color: #707070;
}

.certificado-emision {
  min-width: 80%;
  min-height: 300px;
}

.certificado-emision .certificado {
  border: 1px solid #707070;
  padding: 20px;

}

@media (max-width: 1000px) {
  button.custom-biosalud {
    margin-top: 10px;
  }
  article.turn-step.calendar {
    width: 100%;
  }
  .calendarContainer {
    flex-flow: column nowrap;
  }
  .calendar-turns {
    width: 100%;
  }
  .calendarContainer .selected-day-turn {
    width: 100%;
  }
  .calendar-turns tbody tr td article h5 {
    font-size: 10px;
  }
  .calendar-turns tbody tr td article {

  }
}

/* HOME PAGE */

.homePage, .turnsPage {
  background: #fff !important;
}

#home {
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
}

#home article {
  height: 80vh;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  overflow: hidden;
  justify-content: space-between;
  align-self: flex-end;
}

#home .rm-carousel {
  margin-left: 10px;
  width: 70%; 
  height: 80vh;
  overflow: hidden;
}

#home .rm-carousel .item {
  position: relative;
  width: 0;
  transition: width 1.5s;
  overflow: hidden;
  display: inline-block;
}
#home .rm-carousel .item.active {
  width: 100%;
}

.espejado-camilalila {
  transform: scalex(-1);
}

#home .rm-carousel .item div.image{
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 15%;
  left: 0;
  width: 100%;
  max-height: 70%;
  min-height: 70%;
}
#home .rm-carousel .item span {
    position: absolute;
  background: #707070b4;
  color: #fff;
  padding: 20px;
  border-radius: var(--border-radius);
  right: 100px;
  width: 400px;
  overflow: hidden;
  text-align: justify;
  bottom: 60px;
  cursor: pointer;
  transition: background .3s;
}

#home .rm-carousel .item span:hover {
  background: #707070;
}

#home .fixed-img {
  max-width: 500px;
  min-width: 500px;
  height: 500px; 
  background: var(--primary-color);
  position: relative;
  align-self: flex-end;
}
#home .fixed-img img {
  width: 100%;  
  height: 100%;
  right: -60px;
  top: -50px;
  position: absolute;
  z-index: 4;
}

.verMasMenu {
  width: 100%;
  min-height: 90%;
  background: var(--primary-color);
  border-radius: var(--border-radius);
  color: #fff;
  padding: 10px;
  margin-bottom: 10px;
}

.verMasMenu p {
  font-size: 10px;
}

.content {
  height: 20vh;
  overflow: hidden;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  /* position: sticky; */
  top: 0;
  left: 0;
  width:100%;
  padding-left: 2rem;
  z-index: 1 !important;
  padding-right: 0 !important;
}


.content a img {
  width: 120%;
}

.content nav {
  width: 60%;
  border-radius: var(--border-radius);
    display: flex;
    overflow: hidden;
    background-color: var(--primary-color);
    border-radius: var(--border-radius) 0 0 var(--border-radius);
}

.content nav a { 
  color: #fff; 
  font-weight: 400;  
  width: 25%;
  padding: 10px;
  text-align: center;
  font-size: 20px;
  background: var(--primary-color);
  transition: background .3s;
}

.content nav a:hover {
  background: #707070;
  color: #fff !important;
}

nav.not-title {
  justify-content: flex-start;  
  padding-left: 20px;
}

nav h4 {
  margin: 0
}

.content nav h4 {
    color: #fff; 
  padding: 10px 0;
  font-weight: 400;  
  font-size: 20px;
}

.content nav a:hover { 
  color: #000; 
}

.barra-divisor {
  border: 1px solid #fff;
  height: 2.8rem;
  width: 1px;
  background: #fff;
}

section.home { 
  margin-top: 2vh;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}
#about-us .personal-altamente-calificado{
   display: flex;
   flex-flow: column nowrap;
   align-items: center;
   justify-content: center;
}

#about-us .personal-altamente-calificado span{
  width: 140px;
  height: 140px;
  overflow: hidden;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: #707070;
  box-shadow: 0 0 10px #aaa;

  margin: 0;
  margin-bottom: 10px;
}

#about-us .personal-altamente-calificado span img {
  width: 100px;
}

#about-us .personal-altamente-calificado h4{
  text-align: center;
}

#about-us .carrousel-md {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border-radius: var(--border-radius);
  overflow: hidden;
  margin: 0 auto;
}

#about-us .carrousel-md.unique-picture {
  justify-content: center !important;
}

#about-us .carrousel-md.two-picture {
  /* border-radius: var(--border-radius); */
  width: 50%;
}
#about-us .carrousel-md.two-picture article {
  /* border-radius: var(--border-radius); */
  width: 50%;

}
#about-us .carrousel-md.third-picture {
  /* border-radius: var(--border-radius); */
  width: 75%;

}
#about-us .carrousel-md.third-picture article {
  /* border-radius: var(--border-radius); */
  width: 50%;
}
#about-us .carrousel-md.third-picture-33 article {
  /* border-radius: var(--border-radius); */
  width: 33.3%;
}


#about-us .carrousel-md img.logo {
  width: 15%;
}

#about-us .carrousel-md{
  width: 100%;
  height: 200px;
}
#about-us .carrousel-md article {
width: 25%;
position: relative;
cursor: pointer;
overflow: hidden;
height: 100%;
}

#about-us .carrousel-md article span.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size:  cover;
  background-repeat: no-repeat;
  background-position: center;
}

#about-us .carrousel-md article span.cont {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0;
  display: flex;
  transition: opacity .3s;  
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-end;
}

#about-us .carrousel-md article.viewInfo span {
  opacity: .7;
}

#about-us .carrousel-md article span h6, #about-us .carrousel-md article span h4 {
  color: #fff;
  text-align: center;
}

#services article{
  padding: 0 2rem 4rem 2rem;
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
}

#services article button {
  bottom: 2rem; 
}
#services article img{
  width: 100%;

}
#services article p{
  font-size: 1rem;
  margin-bottom: 2rem;
}

.primary-color {
  color: var(--primary-color) !important;
}

#certs form {
  padding: 2rem 4rem;
  border-radius: var(--border-radius);
  background: #70707083;
  color: var(--font-white);
  box-shadow: 0 0 10px #aaa;
}

#contact form textarea {
  min-height: 120px;
}

#clients {
    display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
}

#clients div.row article{
  display: flex;
  justify-content: center;
  align-items: center;
}

#clients img {
  max-width: 50%;
}

#clients div.row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

}

.carousel-personal {
	border-radius: var(--border-radius);
}

.carousel-personal .slide {
	height: max-content !important;
	background: var(--primary-color);
	border-radius: var(--border-radius);
	overflow: hidden;
}

.image_container {
	width: 100%;
	height: 500px;
	position: relative;
}

.image_container span {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 500px;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}


.carousel-personal .slider {
}

.carousel-personal p.legend {
	background: var(--primary-color) !important;
	opacity: 1 !important;
}

footer {
  background: var(--primary-color);
  color: var(--font-white);
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.fixed-buttons-right {
  position: fixed;
  bottom: 50px;
  right: 20px;
  display: flex;
  flex-flow: column nowrap;
  z-index: 2 !important;

}

.fixed-buttons-right a {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 100%;  
  background-color: var(--secondary-color);
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background .3s;
  box-shadow: 0 0 10px #aaa;
}

.fixed-buttons-right a:hover {
  background-color: #707070;
}

.fixed-buttons-right a i {
  color: #fff;
  font-size: 25px;
}

footer a {
  margin-right: 20px;
}
footer a:hover i {
  color: #707070;
}

footer a i {
  color: #fff;
  font-size: 25px;
  transition: color .3s;
}

@media (max-width: 768px) {
  .content {
	flex-flow: row wrap;
  }
  .content nav {
      display: block;
	  margin: 0;
	  justify-self: center;
      border-radius: 10px;
	  width: 100%;
	  text-align: center;
	  margin-right: 2rem;
  }

  .content nav a {
	  font-size: 20px !important;
  }

  .fixed-img { display: none !important; }
  .rm-carousel { width: 100% !important; }
  .content a img {
    width: 50%;
    display: block;
    margin: auto;
  }
  #clients img {
    max-width: 90%;
  }

  .text-center-foot {
    display: block;
    text-align: center;
  }
}

@media (max-width: 960px) {
  #home .rm-carousel .item span {
    right: 50px;
    width: 300px;
  }
  .content nav a {
    font-size: 16px;
  }

}
@media (max-width: 500px) {
	.root {
		overflow: hidden !important;
	}
	.content nav a {
		font-size: 16px !important;
	}
}